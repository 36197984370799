import React from "react";
// import async from "../components/Async";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  dashboardLayoutRoutes,
  authLayoutRoutes,
  // protectedRoutes,
} from "./index";

import { useSelector } from "react-redux";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";

const childRoutes = (Layout, routes) =>
  routes.map(({ component: Component, guard, children, path }, index) => {
    const Guard = guard || React.Fragment;

    return children ? (
      children.map((element, index) => {
        const Guard = element.guard || React.Fragment;

        return (
          <Route
            key={index}
            path={element.path}
            exact
            render={(props) => (
              <Guard>
                <Layout>
                  <element.component {...props} />
                </Layout>
              </Guard>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Guard>
            <Layout>
              <Component {...props} />
            </Layout>
          </Guard>
        )}
      />
    ) : null;
  });

function Routes() {
  const auth = useSelector((state) => state.authReducer);
  // const [authdata, setauthdata] = useState([]);
  // useSelector((state) => {
  //   setauthdata(state);
  //   console.log(authdata, "helloo");
  // });

  return (
    <Router>
      <Switch>
        {/* <Route
          //  render={() => (
          //    <AuthLayout>
          //      <Page404 />
          //    </AuthLayout>
          //  )}
          path="/"
          component={SignIn}
        > */}
        {childRoutes(AuthLayout, authLayoutRoutes)}

        {/* {authdata != []
          ? childRoutes(DashboardLayout, dashboardLayoutRoutes)
          : null} */}
        {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
        {/* {childRoutes(DashboardLayout, protectedRoutes)} */}

        {/* {childRoutes(PresentationLayout, presentationLayoutRoutes)} */}
        <Route
          path="/"
          render={() => {
            if (auth?.data === undefined) {
              return <Redirect to="/auth/sign-in" />;
            } else {
              return <Redirect to="/dashboard/allTrips" />;
            }
          }}
          // component={SignIn}
        />
        {/* </Route> */}
      </Switch>
    </Router>
  );
}

export default Routes;
