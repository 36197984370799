import {
  Backdrop,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography as MuiTypography,
} from "@material-ui/core";
import NativeSelect from "@mui/material/NativeSelect";

import { DeleteForever, Edit } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { addDays } from "date-fns";

import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { post, URL } from "../../api";
import CustomPagination from "../../components/CustomPagination";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const ModalBody = styled(Box)`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 50px;
  outline: none;
  ${(props) => props.theme.breakpoints.down("md")} {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding: 50px;
    outline: none;
  }
  // ${(props) => props.theme.breakpoints.down("sm")} {
  //   width:92%;
  //   max-height:80vh;
  //   overflow-y:scroll;
  // }
`;
const DeleteModalBody = styled(Box)`
  background-color: white;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  width: 40%;
  padding: 50px;
  outline: none;
  ${(props) => props.theme.breakpoints.down("md")} {
    background-color: white;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    width: 80%;
    padding: 50px;
    outline: none;
  }
  // ${(props) => props.theme.breakpoints.down("sm")} {
  //   width:92%;
  //   max-height:80vh;
  //   overflow-y:scroll;
  // }
`;

const Divider = styled(MuiDivider)(spacing);

const TableGrid = styled(Grid)`
  margin-top: 20px;
`;
const LinkBreadcrumbs = styled(Link)`
  color: #333;
`;
const DeleteModal = styled(Modal)``;

const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles((theme) => ({
  modalStyle: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
  contentStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "1px",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));
// eslint-disable-next-line no-empty-pattern
function ViewStudents({}) {
  const [rows, setRows] = useState([]);
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  // eslint-disable-next-line
  const [Filter, setFilter] = useState("All");
  // const [next, setNext] = useState(0);
  // // eslint-disable-next-line
  // const [previous, setPrevious] = useState(10);
  // eslint-disable-next-line
  const [viewReceiptModal, setviewReceiptModal] = useState(false);

  const [editFirstName, setEditFirstName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const [editMiddleName, setEditMiddleName] = useState("");

  const [editType, setEditType] = useState("");
  const [studentId, setStudentId] = useState("");

  // eslint-disable-next-line
  const [EditTripid, setEditTripid] = useState("");
  const [EditUserid, setEditUserid] = useState("");

  // Create Student Edit Model Start
  const [createStdModal, setCreateStdModal] = useState(false);
  const [createStdFirstName, setCreateStdFirstName] = useState("");
  const [createStdMiddleName, setCreateStdMiddleName] = useState("");
  const [createStdLastName, setCreateStdLastName] = useState("");
  const [createStdType, setCreateStdType] = useState(0);

  //pagination
  const [perpage, setPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  // eslint-disable-next-line
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 1),
    key: "selection",
  });
  const location = useLocation();
  // console.log("location", location.state);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line

  // eslint-disable-next-line
  useEffect(() => {
    // eslint-disable-next-line
    // eslint-disable-next-line
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (location?.state?.row?.trip_id) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      getStudents();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Filter, selectionRange, page, perpage]);
  // eslint-disable-next-line

  const handleOpen = (row) => {
    setOpen1(true);
    // console.log("row1222", row);
    setEditFirstName(row.first_name);
    setEditLastName(row.last_name);
    setEditMiddleName(row.middle_name);
    setEditType(row.type);
    setEditTripid(row.trip_id);
    setEditUserid(row.id);
  };
  const handleClose2 = () => setOpen2(false);
  const handleClose = () => setOpen1(false);

  const getStudents = async () => {
    const data = {
      trip_id: location?.state?.row?.trip_id,
      getAllRows: 0,
      endIndex: 10,
      offset: page * perpage,
      perpage: perpage,
    };
    try {
      // console.log("getStudents ", data);
      const getStudents = await post(URL.getStudents, data);
      setTotal(getStudents.data.data.total);
      setRows(getStudents.data.data.results);
    } catch (error) {
      // console.log("error", error);
    }
  };
  const handleClick = (updatedStudents) => {
    // console.log("updatedStudents123", updatedStudents);
    if (updatedStudents?.data?.data?.status === 200) {
      enqueueSnackbar(updatedStudents?.data?.data?.message, {
        variant: "success",
        // persist: true,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
    }
    setOpen1(false);
    getStudents();
  };
  const UpdateStudentsArray = async () => {
    const data = {
      user_id: EditUserid,
      first_name: editFirstName,
      middle_name: editMiddleName,
      last_name: editLastName,
      type: editType,
    };
    // console.log("updated body", data);
    const updatedStudents = await post(URL.updateStudents, data);
    // console.log("updatedStudents", updatedStudents);
    return handleClick(updatedStudents);
  };
  const deleteStudentF = async () => {
    const data = {
      user_id: studentId,
    };
    // console.log("deleted data", data);
    const deletedStudent = await post(URL.deleteStudent, data);
    // console.log("deletedStudent", deletedStudent);
    setOpen2(false);
    return handleClick(deletedStudent);
  };
  // const NextFunc = () => {
  //   if (rows.length > 0) {
  //     setNext((prev) => prev + 10);
  //   }
  // };
  // const PrevFunc = () => {
  //   setNext((prev) => Math.max(prev - 10, 0));
  // };
  const handleOpen2 = (row) => {
    // console.log("hanldeopen----");
    setStudentId(row.id);
    setOpen2(true);
    // checkifTripsExist(row);
    // deleteOrganizationTripF(row);
  };

  // Create Student Handles

  const createStudent = async () => {
    const data = {
      trip_id: location?.state?.row?.trip_id,
      first_name: createStdFirstName,
      middle_name: createStdMiddleName,
      last_name: createStdLastName,
      type: createStdType,
      is_login: 1,
      last_login: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      token: "",
      activation_hash: "",
      status: 1,
      is_deleted: 0,
      created: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      modified: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
    };
    // console.log("updated body", data);
    const updatedStudents = await post(URL.createStudent, data);

    // console.log(updatedStudents);
    // console.log("updatedStudents", updatedStudents);

    if (updatedStudents?.data?.data?.status === 200) {
      enqueueSnackbar(updatedStudents?.data?.data?.message, {
        variant: "success",
        // persist: true,
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
    }
    setCreateStdModal(false);
    getStudents();
  };

  const handleCreateStdModalOpen = () => {
    setCreateStdFirstName("");
    setCreateStdMiddleName("");
    setCreateStdLastName("");
    setCreateStdModal(true);
  };

  const handleCreateStdModalClose = () => {
    setCreateStdModal(false);
  };

  // console.log("locccc123", rows);
  return (
    <React.Fragment>
      <Helmet title="Bethel Trips | Dashboard" />
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography style={{ color: "#333" }} variant="h3" gutterBottom>
            {location.state.row ? location?.state?.row?.name : ""}
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <LinkBreadcrumbs exact to={`${"/dashboard/allTrips"}`}>
              Trips
            </LinkBreadcrumbs>
            <Typography>View Students</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid item>
        <Divider style={{ color: "rgb(17, 24, 39)" }} my={6} />
      </Grid>

      {/* Create Student Button */}
      <Grid justify="space-between" container spacing={6}>
        <Grid item>{/* <Action  /> */}</Grid>
        <Grid item>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "#333",
              color: "white",
              marginTop: "30px",
              width: "100%",
            }}
            onClick={() => handleCreateStdModalOpen()}
          >
            Add Trip Member
          </Button>
        </Grid>
      </Grid>

      <TableGrid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Middle Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length > 0
                ? rows?.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell align="left" style={{ width: 200 }}>
                        {row.first_name}
                      </TableCell>
                      <TableCell align="left" style={{ width: 200 }}>
                        {row.middle_name ? row.middle_name : "--"}
                      </TableCell>
                      <TableCell align="left" style={{ width: 200 }}>
                        {row?.last_name}
                      </TableCell>
                      <TableCell align="left" style={{ width: 200 }}>
                        {row.type === 1 ? "Leader" : "Member"}
                      </TableCell>
                      <TableCell onClick={() => handleOpen(row)}>
                        <Edit />
                      </TableCell>
                      <TableCell onClick={() => handleOpen2(row)}>
                        <DeleteForever
                          color="#333"
                          style={{ cursor: "pointer" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
          <CustomPagination
            total={total}
            perpage={perpage}
            page={page}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </TableContainer>
      </TableGrid>
      {rows.length > 0 ? null : (
        <Grid container justify="center">
          <p style={{ textAlign: "center", margin: "10px", color: "#333" }}>
            Students Not found
          </p>
        </Grid>
      )}

      <Grid>
        <Modal
          hideBackdrop
          open={open1}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className={classes.modalStyle}
        >
          <ModalBody
            className={classes.contentStyle}
            // style={{
            //   backgroundColor: "white",
            //   display: "flex",
            //   flexDirection: "column",
            //   justifyContent: "center",
            //   alignItems: "center",
            //   // marginTop: "20%",
            //   width: "50%",
            //   padding: "50px",
            //   outline: "none",
            // }}
          >
            <h2
              id="child-modal-title"
              style={{ color: "#333", fontSize: "30px" }}
            >
              Edit Student Details
            </h2>
            <Grid container justify="center">
              <Grid item xs={12} md={12} sm={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    style={{ marginTop: "20px" }}
                    value={editFirstName ? editFirstName : ""}
                    onChange={(e) => setEditFirstName(e.target.value)}
                    fullWidth
                    placeholder="First Name"
                    color="secondary"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    value={editMiddleName ? editMiddleName : ""}
                    onChange={(e) => setEditMiddleName(e.target.value)}
                    placeholder="Middle Name"
                    color="secondary"
                    style={{ marginTop: "40px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    value={editLastName ? editLastName : ""}
                    onChange={(e) => setEditLastName(e.target.value)}
                    placeholder="Last Name"
                    color="secondary"
                    style={{ marginTop: "40px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <NativeSelect
                    defaultValue={editType}
                    fullWidth
                    style={{ marginTop: "40px" }}
                    onChange={(e) => {
                      setEditType(e.target.value);
                    }}
                  >
                    <option value={1}>Leader</option>
                    <option value={0}>Member</option>
                  </NativeSelect>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ButtonGroup
                  style={{ marginTop: "60px" }}
                  fullWidth
                  variant="contained"
                  aria-label="outlined primary button group"
                >
                  <Button
                    onClick={() => UpdateStudentsArray()}
                    style={{
                      backgroundColor: "#333",
                      color: "white",
                    }}
                  >
                    UPDATE
                  </Button>
                  <Button
                    onClick={() => handleClose()}
                    style={{
                      marginLeft: "5px",
                      color: "#333",
                      backgroundColor: "white",
                      border: "1px solid #333",
                    }}
                  >
                    CANCEL
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </ModalBody>
        </Modal>
      </Grid>

      {/* Edit Modal */}
      <Grid>
        <Modal
          hideBackdrop
          open={createStdModal}
          onClose={handleCreateStdModalClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className={classes.modalStyle}
        >
          <ModalBody
            className={classes.contentStyle}
            // style={{
            //   backgroundColor: "white",
            //   display: "flex",
            //   flexDirection: "column",
            //   justifyContent: "center",
            //   alignItems: "center",
            //   // marginTop: "20%",
            //   width: "50%",
            //   padding: "50px",
            //   outline: "none",
            // }}
          >
            <h2
              id="child-modal-title"
              style={{ color: "#333", fontSize: "30px" }}
            >
              Create Trip Member
            </h2>
            <Grid container justify="center">
              <Grid item xs={12} md={12} sm={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    style={{ marginTop: "20px" }}
                    value={createStdFirstName}
                    onChange={(e) => setCreateStdFirstName(e.target.value)}
                    fullWidth
                    placeholder="First Name"
                    color="secondary"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    value={createStdMiddleName}
                    onChange={(e) => setCreateStdMiddleName(e.target.value)}
                    placeholder="Middle Name"
                    color="secondary"
                    style={{ marginTop: "40px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    value={createStdLastName}
                    onChange={(e) => setCreateStdLastName(e.target.value)}
                    placeholder="Last Name"
                    color="secondary"
                    style={{ marginTop: "40px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <NativeSelect
                    defaultValue={0}
                    fullWidth
                    style={{ marginTop: "40px" }}
                    onChange={(e) => setCreateStdType(e.target.value)}
                  >
                    <option value={1}>Leader</option>
                    <option value={0}>Member</option>
                  </NativeSelect>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ButtonGroup
                  style={{ marginTop: "60px" }}
                  fullWidth
                  variant="contained"
                  aria-label="outlined primary button group"
                >
                  <Button
                    onClick={() => createStudent()}
                    style={{
                      backgroundColor: "#333",
                      color: "white",
                    }}
                  >
                    Create
                  </Button>
                  <Button
                    onClick={() => handleCreateStdModalClose()}
                    style={{
                      marginLeft: "5px",
                      color: "#333",
                      backgroundColor: "white",
                      border: "1px solid #333",
                    }}
                  >
                    CANCEL
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </ModalBody>
        </Modal>
      </Grid>

      <DeleteModal
        hideBackdrop
        open={open2}
        onClose={handleClose2}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className={classes.modalStyle}
      >
        <DeleteModalBody
          className={classes.contentStyle}
          // style={{
          //   backgroundColor: "white",
          //   width: "50%",
          //   padding: "50px",
          //   outline: "none",
          // }}
        >
          <h2
            id="child-modal-title"
            style={{ color: "#333", fontSize: "30px" }}
          >
            DELETE
          </h2>
          <Grid item>
            <Divider style={{ color: "rgb(17, 24, 39)" }} my={6} />
          </Grid>
          <p>Are you sure you want to delete this Member?</p>
          <Grid container>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <ButtonGroup
                style={{ marginTop: "60px" }}
                fullWidth
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button
                  onClick={() => deleteStudentF()}
                  style={{
                    backgroundColor: "#333",
                    color: "white",
                  }}
                >
                  OK
                </Button>
                <Button
                  onClick={() => handleClose2()}
                  style={{
                    marginLeft: "5px",
                    color: "#333",
                    backgroundColor: "white",
                    border: "1px solid #333",
                  }}
                >
                  CANCEL
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </DeleteModalBody>
      </DeleteModal>
    </React.Fragment>
  );
}

export default ViewStudents;
