import * as types from "../../constants";
import {
  signUp as authSignUp,
  resetPassword as authResetPassword,
} from "../../services/authService";

export default class LoginAction {
  static GET_LOGIN = "GET_LOGIN";
  static GET_LOGOUT = "GET_LOGOUT";
  static GET_ORGANIZATION_NAME = "GET_ORGANIZATION_NAME";

  static getLogin(data) {
    return {
      type: this.GET_LOGIN,
      data,
    };
  }
  static getLogout(data) {
    return {
      type: this.GET_LOGOUT,
      // data,
    };
  }
  static getOrganizationName(data) {
    return {
      type: this.GET_ORGANIZATION_NAME,
      data,
    };
  }
}

export function signUp(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return authSignUp(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
          id: response.id,
          email: response.email,
          name: response.name,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function signOut() {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };
}

export function resetPassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}
