import Axios from "axios";
import { store } from "../redux/store/index";

export const URL = {
  login: "api/admin/auth/adminLogin",
  getYearTravelTrips: "api/admin/trip/getYearTravelTrips",
  logout: "api/admin/auth/logout",
  updateTrip: "api/admin/trip/updateTrips",
  addTrip: "api/admin/trip/addTrips",
  getTripTransaction: "api/admin/trip/getTripTransaction",
  addTripUser: "api/admin/trip/addTripUser",
  uploadCSV: "api/admin/trip/upload",
  getTripUser: "api/admin/trip/getTripUser",
  updateStudentDetail: "api/admin/trip/updateStudentDetails",
  updateCheckAmount: "api/admin/trip/updateCheckAmount",
  downloadExcel: "api/admin/trip/createExcel",
  addTripOrganization: "api/admin/trip/addTripOrganization",
  getTripOrganization: "api/admin/trip/getTripOrganization",
  addAdmin: "api/admin/auth/signup",
  getAdmin: "api/admin/auth/getAdmins",
  updateAdmin: "api/admin/auth/editAdmins",
  deleteAdmin: "api/admin/auth/updateAdmins",
  downloadZip: "api/admin/trip/getReceipts",
  readTransactionReceipt: "api/admin/trip/viewTransactionReceipts",
  getStudents: "api/admin/trip/viewStudents",
  updateStudents: "api/admin/trip/updateStudent",
  createStudent: "api/admin/trip/createStudent",
  deleteStudent: "api/admin/trip/deleteStudent",
  updatedTripOrganization: "api/admin/trip/editOrganizationTrip",
  deleteTripOrganization: "api/admin/trip/deleteOrganizationTrip",
  checkifTripExist: "api/admin/trip/CheckOrganizationTripExist",
  getTripDetail: "api/admin/trip/getTripDetails",
  addCategory: "api/admin/trip/addCategory",
  getCategory: "api/admin/trip/getCategories",
  deleteCategory: "api/admin/trip/deleteCategory",
  getCategories: "api/admin/trip/getCategories",
  getTripCurrency: "api/admin/trip/getTripCurrency",
  getOrganization: "api/admin/trip/getOrganization",
  getTransactionTotalSpent: "api/admin/trip/getTotalSpentOfTransaction",
  updateLeaderPasscode: "api/admin/trip/updateLeaderPasscode",
  updateStudentPasscode: "api/admin/trip/updateStudentPasscode",
};

export const headers = {
  "content-type": "application/json",
};

export const get = async (endpoint, noToken) => {
  let token = store.getState().authReducer?.data?.token;
  // console.log("from get endpoint", endpoint);
  // console.log("token--", store.getState().authReducer.data.token);
  return new Promise((resolve, reject) => {
    Axios.get(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        // console.log("in then", response);
        resolve(response);
      })
      .catch((error) => {
        // console.log(`err in get  ${endpoint}`, error);
        reject(error);
      });
  });
};

export const post = async (endPoint, data) => {
  let token = store.getState().authReducer?.data?.token;
  // console.log("from post endpoint", endPoint, data);
  // console.log("token", token);
  return new Promise((resolve, reject) => {
    Axios.post(endPoint, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        // console.log("res of post", response);
        resolve(response);
      })
      .catch((error) => {
        // console.log("api catch1", error);
        // console.log("api catch2", error.message);
        // console.log("api catch3", error.response);

        reject(error);
      });
  });
};
