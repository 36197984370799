import { TablePagination } from "@material-ui/core";
import React from "react";

function CustomPagination(props) {
  const { total, perpage, page, setPage, setPerPage } = props;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 15]}
      component="div"
      count={total}
      rowsPerPage={perpage}
      page={page}
      // onPageChange={handleChangePage}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}

export default CustomPagination;
