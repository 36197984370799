// import * as types from "../../constants";
import LoginAction from "../actions/authActions";

export default function User(
  state = {
    User: "USER_ONE",
    data: null,
    organizationName: "2nd Year Travel",
    organizationId: 1,
  },
  action
) {
  // console.log("LOGIN action", action);
  // console.log("LOGIN action------", LoginAction);
  switch (action.type) {
    case LoginAction.GET_LOGIN:
      // console.log("getlogin");
      return {
        ...state,
        data: action.data,
      };
    case LoginAction.GET_LOGOUT:
      // console.log("getlogout");
      return {
        ...state,
        data: undefined,
        // organizationName: "",
      };
    case LoginAction.GET_ORGANIZATION_NAME:
      // console.log("getlogout");
      return {
        ...state,
        organizationName: action.data.name,
        organizationId: action.data.id,
      };
    default:
      return state;
  }
}
