import {
  Avatar,
  Badge,
  Chip,
  Drawer as MuiDrawer,
  Grid,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
// import { ReactComponent as Logo } from "../vendor/logo.svg";
import { useSelector } from "react-redux";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import WebLogo from "../pages/dashboards/Analytics/Bethel_logo.svg";
import { sidebarRoutes as routes } from "../routes/index";
import "../vendor/perfect-scrollbar.css";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: #333;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: #333;
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: #333;
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: #333333;
  }
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  color: ${(props) => props.theme.sidebar.color};
  margin-top: 10px;

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &.${(props) => props.activeClassName} {
    background-color: #333333;
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: white;
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: #333;

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: #333;
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const SidebarFooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  span {
    background-color: green;
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) => {
  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {/* {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null} */}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
};

const Sidebar = ({ classes, staticContext, location, ...rest }) => {
  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index) => {
    // console.log("toggle click", index);
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
    // console.log("openRoutes", openRoutes);
  };
  const data = useSelector((data) => {
    return data;
  });
  // eslint-disable-next-line
  const [NewCategory, setNewCategory] = useState([]);
  const history = useHistory();
  // console.log("routes", routes);
  const NewCategoryAdded = () => {
    if (data?.authReducer?.data?.admin?.admin_role_id !== 3) {
      var NewCategory = routes?.filter((data, i) => i < 2);
      // console.log("NewCategory", NewCategory);
      setNewCategory(NewCategory);
    } else {
      // eslint-disable-next-line
      var NewCategory = routes?.filter((data, i) => data);
      // console.log("NewCategory", NewCategory);
      setNewCategory(NewCategory);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    NewCategoryAdded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/" button>
        <img
          alt="sunset"
          src={WebLogo}
          style={{
            marginTop: 20,
            width: 150,
            height: 100,
          }}
        />
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {NewCategory.length > 0
              ? NewCategory.map((category, index) => {
                  // console.log("if category", category);
                  return (
                    <React.Fragment key={index}>
                      {category.header ? (
                        <SidebarSection style={{ fontWeight: "700" }}>
                          {category.header}
                        </SidebarSection>
                      ) : null}

                      {NewCategory && category.icon ? (
                        <React.Fragment key={index}>
                          <SidebarCategory
                            isOpen={!openRoutes[index]}
                            isCollapsable={true}
                            name={category.id}
                            icon={category.icon}
                            button={true}
                            onClick={() => history.push(category.path)}
                          />

                          {/* <Collapse
                            in={openRoutes[index]}
                            timeout="auto"
                            unmountOnExit
                          >
                            {NewCategory.map((route, index) => (
                              <SidebarLink
                                key={index}
                                name={route.name}
                                to={route.path}
                                icon={route.icon}
                                badge={route.badge}
                                // activeClassName="active"
                                // style={{ backgroundColor: "red" }}
                              />
                            ))}
                          </Collapse> */}
                        </React.Fragment>
                      ) : category.icon ? (
                        <SidebarCategory
                          isCollapsable={false}
                          name={category.id}
                          to={category.path}
                          activeClassName="active"
                          component={NavLink}
                          icon={category.icon}
                          exact
                          button
                          badge={category.badge}
                        />
                      ) : null}
                    </React.Fragment>
                  );
                })
              : NewCategory.map((category, index) => {
                  // console.log("else category", category);
                  return (
                    <React.Fragment key={index}>
                      {category.header ? (
                        <SidebarSection style={{ fontWeight: "700" }}>
                          {category.header}
                        </SidebarSection>
                      ) : null}

                      {category.children && category.icon ? (
                        <React.Fragment key={index}>
                          <SidebarCategory
                            isOpen={!openRoutes[index]}
                            isCollapsable={true}
                            name={category.id}
                            to={category.path}
                            icon={category.icon}
                            button={true}
                            onClick={() => toggle(index)}
                          />

                          {/* <Collapse
                            in={openRoutes[index]}
                            timeout="auto"
                            unmountOnExit
                          >
                            {category.children.map((route, index) => (
                              <SidebarLink
                                key={index}
                                name={route.name}
                                to={route.path}
                                icon={route.icon}
                                badge={route.badge}
                                // activeClassName="active"
                                // style={{ backgroundColor: "red" }}
                              />
                            ))}
                          </Collapse> */}
                        </React.Fragment>
                      ) : category.icon ? (
                        <SidebarCategory
                          isCollapsable={false}
                          name={category.id}
                          to={category.path}
                          activeClassName="active"
                          component={NavLink}
                          icon={category.icon}
                          exact
                          button
                          badge={category.badge}
                        />
                      ) : null}
                    </React.Fragment>
                  );
                })}
          </Items>
        </List>
      </Scrollbar>
      {/* if (data?.authReducer?.data?.admin?.admin_role_id !== 3) {
                var NewCategory = category?.children?.filter(
                  (data, i) => i !== 3
                );
              }
              if (NewCategory?.length > 0) {
                return (
                  <React.Fragment key={index}>
                    {category.header ? (
                      <SidebarSection style={{ fontWeight: "700" }}>
                        {category.header}
                      </SidebarSection>
                    ) : null}

                    {NewCategory && category.icon ? (
                      <React.Fragment key={index}>
                        <SidebarCategory
                          isOpen={!openRoutes[index]}
                          isCollapsable={true}
                          name={category.id}
                          icon={category.icon}
                          button={true}
                          onClick={() => toggle(index)}
                        />

                        <Collapse
                          in={openRoutes[index]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {NewCategory.map((route, index) => (
                            <SidebarLink
                              key={index}
                              name={route.name}
                              to={route.path}
                              icon={route.icon}
                              badge={route.badge}
                              // activeClassName="active"
                              // style={{ backgroundColor: "red" }}
                            />
                          ))}
                        </Collapse>
                      </React.Fragment>
                    ) : category.icon ? (
                      <SidebarCategory
                        isCollapsable={false}
                        name={category.id}
                        to={category.path}
                        activeClassName="active"
                        component={NavLink}
                        icon={category.icon}
                        exact
                        button
                        badge={category.badge}
                      />
                    ) : null}
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={index}>
                    {category.header ? (
                      <SidebarSection style={{ fontWeight: "700" }}>
                        {category.header}
                      </SidebarSection>
                    ) : null}

                    {category.children && category.icon ? (
                      <React.Fragment key={index}>
                        <SidebarCategory
                          isOpen={!openRoutes[index]}
                          isCollapsable={true}
                          name={category.id}
                          icon={category.icon}
                          button={true}
                          onClick={() => toggle(index)}
                        />

                        <Collapse
                          in={openRoutes[index]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {category.children.map((route, index) => (
                            <SidebarLink
                              key={index}
                              name={route.name}
                              to={route.path}
                              icon={route.icon}
                              badge={route.badge}
                              // activeClassName="active"
                              // style={{ backgroundColor: "red" }}
                            />
                          ))}
                        </Collapse>
                      </React.Fragment>
                    ) : category.icon ? (
                      <SidebarCategory
                        isCollapsable={false}
                        name={category.id}
                        to={category.path}
                        activeClassName="active"
                        component={NavLink}
                        icon={category.icon}
                        exact
                        button
                        badge={category.badge}
                      />
                    ) : null}
                  </React.Fragment>
                );
              } */}
      <SidebarFooter>
        <Grid container spacing={2}>
          <Grid item>
            <SidebarFooterBadge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar
                alt={data?.authReducer?.data?.user?.username}
                src="https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png"
              />
            </SidebarFooterBadge>
          </Grid>
          <Grid item>
            <SidebarFooterText style={{ fontWeight: "700" }} variant="body2">
              Admin
            </SidebarFooterText>
            <SidebarFooterSubText
              style={{ fontWeight: "700" }}
              variant="caption"
            >
              {data?.authReducer?.data?.admin?.email}
            </SidebarFooterSubText>
          </Grid>
        </Grid>
      </SidebarFooter>
    </Drawer>
  );
};

export default withRouter(Sidebar);
