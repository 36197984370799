import React from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@material-ui/core";
import LoginAction from "../redux/actions/authActions";
import { post, URL } from "../pages/api";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleClick = () => {
    enqueueSnackbar("Logout error", {
      variant: "error", // or variant: warning | error | info
      // persist: true,
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };

  const handleSignOut = async () => {
    // console.log(userdata?.authReducer?.data?.token, "hello userdata");
    const userLogout = await post(URL.logout);
    // console.log("logout", userLogout);
    if (userLogout.data.token == null) {
      await dispatch(LoginAction.getLogout());
      history.push("/auth/sign-in");
    } else {
      return handleClick();
    }
    //   if (userLogout.) {
    //   console.log(LoginAction, "this is login action");
    //   console.log(LoginAction.getLogout(), "this is login action");

    //   await dispatch(LoginAction.getLogout());
    //   //   history.push("/dashboard/analytics");
    // } else {
    //   alert(response.data.message);
    // }

    // axios({
    //   url: "http://localhost:3000/api/users/logout",
    //   method: "POST",
    //   token: userdata?.authReducer?.data?.token,
    //   headers: {
    //     "x-user-jwt": userdata?.authReducer?.data?.token,
    //     // "Content-Type": "application/json",
    //   },
    // })
    //   .then(async (response) => {
    //     console.log(response, "helloooo respo");
    //     history.push("/auth/sign-in");
    //     if (response.data.success) {
    //       console.log(LoginAction, "this is login action");
    //       console.log(LoginAction.getLogout(), "this is login action");

    //       await dispatch(LoginAction.getLogout());
    //       //   history.push("/dashboard/analytics");
    //     } else {
    //       alert(response.data.message);
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {/* <MenuItem onClick={closeMenu}>Profile</MenuItem> */}
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default UserDropdown;
