import { Category } from "@material-ui/icons";
import {
  AdminPanelSettings,
  CorporateFare,
  ModeOfTravel,
} from "@mui/icons-material";
import React from "react";
import { Users } from "react-feather";
import async from "../components/Async";

import ViewStudents from "../pages/dashboards/ViewStudents";

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));
const AuthGuardAdmin = async(() => import("../components/AuthGuardAdmin"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

const AddTrip = async(() => import("../pages/dashboards/AddTrip"));
const AddOrganizationTrip = async(() =>
  import("../pages/dashboards/AddOrganizationTrip")
);
const AddCategories = async(() => import("../pages/dashboards/AddCategories"));

const YearTravel = async(() => import("../pages/dashboards/YearTravel"));
const ViewAdmin = async(() => import("../pages/dashboards/ViewAdmin"));
const AddAdmins = async(() => import("../pages/dashboards/AddAdmins"));

const ViewActiveTrips = async(() =>
  import("../pages/dashboards/ViewActiveTrips")
);

// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

const tripRoute = {
  id: "Trips",
  path: "/dashboard/allTrips",
  icon: <ModeOfTravel style={{ color: "white" }} />,
  guard: AuthGuard,
  children: null,
  component: YearTravel,
};
const CategoryRoute = {
  id: "Categories",
  path: "/dashboard/categories",
  icon: <Category style={{ color: "white" }} />,
  containsHome: true,
  guard: AuthGuard,
  component: AddCategories,
  childern: null,
};

const OrganizationRoute = {
  id: "Organization",
  path: "/dashboard/addOrganizationTrip",
  icon: <CorporateFare style={{ color: "white" }} />,
  containsHome: true,
  guard: AuthGuardAdmin,
  component: AddOrganizationTrip,
  childern: null,
};
const ViewAdminRoute = {
  id: "View Admin",
  path: "/dashboard/viewAdmin",
  icon: <AdminPanelSettings style={{ color: "white" }} />,
  containsHome: true,
  guard: AuthGuardAdmin,
  component: ViewAdmin,
  childern: null,
};

const TripsRoute = {
  id: "Pages",
  path: "/trip",
  icon: <ModeOfTravel />,

  children: [
    {
      path: "/yeartravel/view",
      component: ViewActiveTrips,
      guard: AuthGuard,
    },
    {
      path: "/yeartravel/viewStudents",
      component: ViewStudents,
      guard: AuthGuard,
    },
    {
      path: "/addTrip",
      component: AddTrip,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/addAdmins",
      component: AddAdmins,
      guard: AuthGuard,
    },
  ],
  component: null,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  path: "/private",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  tripRoute,
  TripsRoute,
  CategoryRoute,
  OrganizationRoute,
  ViewAdminRoute,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
// export const presentationLayoutRoutes = [landingRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  tripRoute,
  CategoryRoute,
  OrganizationRoute,
  ViewAdminRoute,
];
