import React from "react";

// function Demos() {
//   return (
//     <Wrapper>
//       <Heading button={true}>Select a Dashboard Color</Heading>

//       <Box px={4} my={3}>
//         <Alert icon={false} severity="info">
//           <strong>Hello!</strong> Select your style below. Choose the ones that
//           best fits your needs.
//         </Alert>
//       </Box>

//       <Box px={4} my={3}>
//         <Grid container spacing={3}>
//           <Demo title="Dark" theme={THEMES.DARK} />
//           <Demo title="Light" theme={THEMES.LIGHT} />
//           <Demo title="Default" theme={THEMES.DEFAULT} />
//           <Demo title="Blue" theme={THEMES.BLUE} />
//           <Demo title="Green" theme={THEMES.GREEN} />
//           <Demo title="Indigo" theme={THEMES.INDIGO} />
//         </Grid>
//       </Box>

//       {/* <Box my={3} mx={4}>
//         <Button
//           component={Link}
//           to="/documentation/welcome"
//           variant="outlined"
//           color="default"
//           size="large"
//           target="_blank"
//           fullWidth={true}
//         >
//           Documentation
//         </Button>
//       </Box> */}
//       {/* <Box my={3} mx={4}>
//         <Button
//           href="https://material-ui.com/store/items/material-app/"
//           variant="contained"
//           color="primary"
//           size="large"
//           target="_blank"
//           fullWidth={true}
//         >
//           Get Material App
//         </Button>
//       </Box> */}
//     </Wrapper>
//   );
// }

function Settings() {
  return (
    <React.Fragment>
      {/* <Fab color="primary" aria-label="Edit" onClick={toggleDrawer(true)}>
        <PaletteIcon />
      </Fab>
      <Drawer anchor="right" open={state.isOpen} onClose={toggleDrawer(false)}>
        <Demos />
      </Drawer> */}
    </React.Fragment>
  );
}

export default Settings;
